import { Dispatch, SetStateAction } from "react";

import {
  useActiveApprovalRuleQuery,
  useApprovalInfoQuery,
} from "../../api/blockchain/company";
import { useSession } from "../../context/session";
import { CompanyInformation } from "../../types/models/administration";
import { CompanyInvolvement } from "../../types/models/company";
import { LedgerVersion } from "../../types/models/shares";
import { validateApprovalPermissions } from "../History/History.utils";

const useShouldShowApproveButtons = (
  currentCompany: CompanyInformation | CompanyInvolvement,
  date?: LedgerVersion
) => {
  const { user } = useSession();
  const activeApprovalRuleQuery = useActiveApprovalRuleQuery(
    currentCompany.orgNumber
  );
  const versionToApproveApprovalInfo = useApprovalInfoQuery(
    currentCompany.orgNumber,
    date
  );

  if (!date) {
    return { canApprove: false, hasApproved: false };
  }

  const userCanApprove =
    user &&
    activeApprovalRuleQuery.isSuccess &&
    activeApprovalRuleQuery.data !== null &&
    versionToApproveApprovalInfo.isSuccess &&
    versionToApproveApprovalInfo.data !== null &&
    validateApprovalPermissions(
      versionToApproveApprovalInfo.data,
      activeApprovalRuleQuery.data,
      currentCompany,
      user
    );

  const hasTheBoardSelectedAnApprovalPolicy =
    versionToApproveApprovalInfo.isSuccess &&
    versionToApproveApprovalInfo.data !== null &&
    versionToApproveApprovalInfo.data.rule !== "None";

  const isLedgerPendingApprovalByUser =
    user !== undefined &&
    versionToApproveApprovalInfo.isSuccess &&
    versionToApproveApprovalInfo.data !== null &&
    versionToApproveApprovalInfo.data.rule !== "None" &&
    versionToApproveApprovalInfo.data.status === "Pending" &&
    versionToApproveApprovalInfo.data.pendingApprovalBy.some(
      ({ id }) => id === user.id
    );

  return {
    canApprove:
      (hasTheBoardSelectedAnApprovalPolicy && userCanApprove) || false,
    hasApproved: !isLedgerPendingApprovalByUser,
  };
};

type EventFormData = {
  formId: string;
  loading: boolean;
  selectedShareTypes: string[];
};

type EventFormProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: (eventId: string) => void;
  setFormData: Dispatch<SetStateAction<EventFormData>>;
};

export type { EventFormData, EventFormProps };
export { useShouldShowApproveButtons };
