import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";

import {
  Attachments,
  AttachmentsSchema,
  EventAttachments,
  EventAttachmentsSchema,
  SignedUrl,
  SignedUrlSchema,
  SignedUrlWithUserId,
  SignedUrlWithUserIdSchema,
} from "../../types/models/attachments";
import { downloadBlob } from "../../utils/download";
import * as monitoring from "../../utils/monitoring";
import { supportUserId } from "../../utils/user";
import { IRequestError } from "..";
import useClient, { NoContentSchema, URL } from "./client";

const getEventAttachments = async (orgNumber: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<EventAttachments>(
    `${URL.ADMIN}/Attachments/${orgNumber}`
  );

  const result = EventAttachmentsSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const useEventAttachmentsQuery = (
  orgNumber: string
): UseQueryResult<EventAttachments, IRequestError> =>
  useQuery<EventAttachments, IRequestError, EventAttachments, [string, string]>(
    ["eventAttachments", orgNumber],
    () => getEventAttachments(orgNumber)
  );

const getPolicyAttachments = async (orgNumber: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<Attachments>(
    `${URL.ADMIN}/Attachments/Policy/${orgNumber}`
  );

  const result = AttachmentsSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response: { attachments: response }, result },
    });

    return response;
  }

  return result.data;
};

const usePolicyAttachmentsQuery = (
  orgNumber: string
): UseQueryResult<Attachments, IRequestError> =>
  useQuery<Attachments, IRequestError, Attachments, [string, string]>(
    ["policyAttachments", orgNumber],
    () => getPolicyAttachments(orgNumber)
  );

const generateFileHash = async (file: File) => {
  const arrayBuffer = await file.arrayBuffer(); // Read the file as ArrayBuffer
  const buffer = await crypto.subtle.digest("SHA-256", arrayBuffer); // Generate SHA-256 hash
  return btoa(String.fromCharCode(...new Uint8Array(buffer)));
};

const uploadAttachment = async (url: string, file: File, userId?: string) => {
  const client = useClient({ hasAuth: true });
  if (!userId) {
    throw new Error("No user id");
  }

  const hash = await generateFileHash(file);
  const response = await client<SignedUrlWithUserId>(url, {
    method: "PUT",
    body: { type: file.type, name: file.name, hash },
  });

  const result = SignedUrlWithUserIdSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  const uploadUrl = result.data.url;
  const uploadResponse = await fetch(uploadUrl, {
    method: "PUT",
    body: file,
    headers: {
      "x-amz-meta-userid": result.data.id,
      "x-amz-checksum-sha256": hash,
      "Content-Type": file.type,
    },
  });

  if (!uploadResponse.ok) {
    monitoring.captureException(result.error, {
      contexts: { response: { response }, result },
    });

    throw new Error("Upload failed");
  }

  return null;
};

const useUploadEventAttachmentsMutation = (
  orgNumber: string,
  options?: UseMutationOptions<
    unknown,
    IRequestError,
    { files: File[]; eventId: string; userId: string }
  >
) => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    IRequestError,
    { files: File[]; eventId: string; userId: string }
  >(
    async ({ files, eventId, userId }) => {
      return await Promise.all(
        files.map((f) =>
          uploadAttachment(
            `${URL.ADMIN}/Attachments/${orgNumber}/${eventId}`,
            f,
            userId
          )
        )
      );
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: ["eventAttachments", orgNumber],
        });
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

const useUploadPolicyAttachmentsMutation = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, { files: File[] }>
) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, IRequestError, { files: File[] }>(
    async ({ files }) => {
      return await Promise.all(
        files.map((f) =>
          uploadAttachment(
            `${URL.ADMIN}/Attachments/Policy/${orgNumber}`,
            f,
            supportUserId
          )
        )
      );
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: ["policyAttachments", orgNumber],
        });
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

const fetchAttachment = async (url: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<SignedUrl>(url);

  const result = SignedUrlSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    throw new Error("Failed to generate download URL");
  }

  return result;
};

const openAttachment = async (url: string) => {
  const result = await fetchAttachment(url);
  window.open(result.data.url, "_blank", "noopener,noreferrer");
};

const downloadAttachment = async (url: string, filename: string) => {
  const result = await fetchAttachment(url);

  const downloadUrl = result.data.url;
  const downloadResponse = await fetch(downloadUrl);

  if (!downloadResponse.ok) {
    monitoring.captureException(result.error, {
      contexts: { response: { downloadResponse }, result },
    });

    throw new Error("Download failed");
  }

  const blob = await downloadResponse.blob();

  downloadBlob(blob, filename);
};

const downloadEventAttachment = async (
  orgNumber: string,
  eventId: string,
  documentId: string
) =>
  downloadAttachment(
    `${URL.ADMIN}/Attachments/${orgNumber}/${eventId}/${documentId}`,
    documentId
  );

const openEventAttachment = async (
  orgNumber: string,
  eventId: string,
  documentId: string
) =>
  openAttachment(
    `${URL.ADMIN}/Attachments/${orgNumber}/${eventId}/${documentId}`
  );

const downloadPolicyAttachment = async (
  orgNumber: string,
  documentId: string
) =>
  downloadAttachment(
    `${URL.ADMIN}/Attachments/Policy/${orgNumber}/${documentId}`,
    documentId
  );

const deleteEventAttachment = async (
  orgNumber: string,
  eventId: string,
  documentId: string
) => {
  const client = useClient({ hasAuth: true });

  const response = await client<SignedUrl>(
    `${URL.ADMIN}/Attachments/${orgNumber}/${eventId}/${documentId}`,
    { method: "DELETE" }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const useDeleteEventAttachmentMutation = (
  orgNumber: string,
  eventId: string,
  options?: UseMutationOptions<unknown, IRequestError, string>
) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, IRequestError, string>(
    (documentId) => deleteEventAttachment(orgNumber, eventId, documentId),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: ["eventAttachments", orgNumber],
        });
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export {
  downloadEventAttachment,
  downloadPolicyAttachment,
  openEventAttachment,
  useDeleteEventAttachmentMutation,
  useEventAttachmentsQuery,
  usePolicyAttachmentsQuery,
  useUploadEventAttachmentsMutation,
  useUploadPolicyAttachmentsMutation,
};
