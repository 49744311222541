import { ShareType } from "@capchapdev/rell-api";
import { useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { useShareTypesQuery } from "../../../api/blockchain/company";
import { Badge } from "../../../components/design-system/Badge";
import { Button } from "../../../components/design-system/Button";
import { EmptyState } from "../../../components/design-system/EmptyState";
import { FormError } from "../../../components/design-system/FormGroup";
import { TrashIcon } from "../../../components/design-system/icons";
import { TableV2 } from "../../../components/design-system/TableV2";
import { TooltipV2 } from "../../../components/design-system/Tooltip/TooltipV2";
import { useRestrictiveConditionOptions } from "../../../components/ShareTypes/SelectRestrictiveConditions";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { formatNumber } from "../../../utils/format";
import { AddShareType } from "./Components/Add";
import { EditShareType } from "./Components/Edit";
import type { ShareIssueFormState } from "./types";

type ShareClassesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  form: UseFormReturn<ShareIssueFormState>;
  companyShareClasses?: { name: string; votes?: number }[];
};

const NewShareClasses = ({
  currentCompany,
  form,
  companyShareClasses,
}: ShareClassesProps) => {
  const i18n = useTranslation();
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const restrictiveConditions = useRestrictiveConditionOptions();
  const { control, formState, watch } = form;

  const data = watch();
  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber, "");
  const shareTypes = shareTypesQuery.data || [];
  const shareClasses: ShareType[] = [...shareTypes, ...data.shareClasses];
  const shareClassesInBlocks = data.shareRanges.map((x) => x.type);

  return (
    <div className="md:tw-rounded md:tw-border md:tw-p-4">
      <h2 className="tw-pb-4 tw-text-lg tw-font-medium">
        {i18n.t("events.issue.newClasses.title")}
      </h2>
      <div className="tw-flex tw-flex-col tw-gap-4">
        <Controller
          control={control}
          name="shareClasses"
          render={({ field: { onChange, value } }) => (
            <>
              <div>
                <TableV2
                  expandedRows={expandedRows}
                  setExpandedRows={setExpandedRows}
                  columns={[
                    {
                      name: "name",
                      title: i18n.t("label.shareClass"),
                      sortable: false,
                      key: true,
                    },
                    {
                      name: "votes",
                      title: i18n.t("label.votes"),
                      sortable: false,
                      key: true,
                    },
                    {
                      name: "conditions",
                      title: i18n.t("shares.restrictiveConditions"),
                      sortable: false,
                      key: !isTabletOrMobileDevice,
                    },
                    {
                      name: "controls",
                      title: "",
                      sortable: false,
                      key: !isTabletOrMobileDevice,
                    },
                  ]}
                  data={value.map((c, i) => ({
                    key: c.name,
                    name: c.name,
                    votes: formatNumber(c.voteValue),
                    conditions: Object.values(c.condition).some(
                      (enabled) => enabled === true
                    ) ? (
                      <div className="tw-flex tw-flex-wrap tw-gap-2">
                        {Object.entries(c.condition)
                          .map(([k, enabled]) =>
                            enabled ? (
                              <Badge key={k}>
                                {
                                  restrictiveConditions.find(
                                    (condition) => condition.value === k
                                  )?.label
                                }
                              </Badge>
                            ) : undefined
                          )
                          .filter((x) => x !== undefined)}
                      </div>
                    ) : undefined,
                    controls: (
                      <div className="tw-flex tw-items-center tw-justify-start tw-gap-1 md:tw-justify-end">
                        <TooltipV2
                          content={
                            shareClassesInBlocks.includes(c.name)
                              ? i18n.t("events.issue.newClasses.disabled")
                              : undefined
                          }
                        >
                          <EditShareType
                            list={shareClasses}
                            initialValue={c}
                            disabled={shareClassesInBlocks.includes(c.name)}
                            onSuccess={(newValue) =>
                              onChange(
                                value.map((type, index) => {
                                  if (index === i) {
                                    return newValue;
                                  }

                                  return type;
                                })
                              )
                            }
                          />
                        </TooltipV2>
                        <TooltipV2
                          content={
                            shareClassesInBlocks.includes(c.name)
                              ? i18n.t("events.issue.newClasses.disabled")
                              : undefined
                          }
                        >
                          <Button
                            disabled={shareClassesInBlocks.includes(c.name)}
                            variant={
                              isTabletOrMobileDevice ? "outline" : "clean"
                            }
                            size="md"
                            onClick={() =>
                              onChange(
                                value.filter((type) => type.name !== c.name)
                              )
                            }
                          >
                            <TrashIcon className="tw-h-6 tw-w-6" />
                          </Button>
                        </TooltipV2>
                      </div>
                    ),
                  }))}
                />
                {value.length === 0 && (
                  <EmptyState
                    title={i18n.t("events.issue.newClasses.empty.title")}
                    description={i18n.t(
                      "events.issue.newClasses.empty.description"
                    )}
                  />
                )}
              </div>
              <AddShareType
                onSuccess={(newValue) => onChange([...value, newValue])}
                list={shareClasses}
                companyShareClasses={companyShareClasses}
              />
              <FormError className="tw-p-4">
                {formState.errors.shareClasses?.message}
              </FormError>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default NewShareClasses;
