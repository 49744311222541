import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../api/blockchain/events";
import { useCompanyEvents } from "../../hooks/useCompanyEvents";
import FormActions from "../../pages/CompanyShares/FormActions";
import { CompanyInformation } from "../../types/models/administration";
import { CompanyInvolvement } from "../../types/models/company";
import { EntitiesMap, Entity } from "../../types/models/entities";
import {
  LedgerApprovalInitializedEvent,
  LedgerRollbackPending,
} from "../../types/models/events";
import { LedgerVersion } from "../../types/models/shares";
import { getFormattedDate } from "../../utils/date";
import { getEventsWithLabel } from "../../utils/events-utils";
import { Button } from "../design-system/Button";
import { EmptyState } from "../design-system/EmptyState";
import { PlusIcon } from "../design-system/icons";
import { Dialog } from "../design-system/ProgressDialog";
import { EventsTable } from "../EventList/EventsTable";
import { useApproversStatus } from "../History/History.utils";
import { PageWrapper } from "../PageWrapper";
import { StatusBadge } from "../StatusBadge";
import { ApproveButton } from "./actions/ApproveButton";
import { DeleteConfirmation } from "./actions/DeleteConfirmation";
import { PublishButton } from "./actions/PublishButton";
import { RejectButton } from "./actions/RejectButton";
import { Done } from "./components/Done";
import { SelectEvent } from "./components/SelectEvent";
import { ShareholdersComparison } from "./components/ShareholdersComparison";
import {
  EventFormData,
  EventFormProps,
  useShouldShowApproveButtons,
} from "./EventsWizard.utils";

const ApproversList = ({
  approvers,
  badge,
}: {
  approvers: Entity[];
  badge: JSX.Element;
}) => {
  if (approvers.length === 0) {
    return <></>;
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {approvers.map((approver) => (
        <div className="tw-flex tw-justify-between" key={approver.name}>
          <div>{approver.name}</div>
          {badge}
        </div>
      ))}
    </div>
  );
};

type AddEventsPageProps = {
  currentCompany: CompanyInformation | CompanyInvolvement;
  entitiesMap: EntitiesMap;
  showAddEvent: boolean;
  setShowAddEvent: (value: boolean) => void;
  setFormData: Dispatch<SetStateAction<EventFormData>>;
  SelectedEvent?: (props: EventFormProps) => JSX.Element;
  setSelectedEvent: Dispatch<
    SetStateAction<((props: EventFormProps) => JSX.Element) | undefined>
  >;
  ledgerApprovalInitializedEvent?: LedgerApprovalInitializedEvent;
  isReview: boolean;
  isRollback: boolean;
  date?: LedgerVersion;
};

const AddEventsPage = ({
  currentCompany,
  entitiesMap,
  showAddEvent,
  setShowAddEvent,
  SelectedEvent,
  setSelectedEvent,
  setFormData,
  ledgerApprovalInitializedEvent,
  isReview,
  isRollback,
  date,
}: AddEventsPageProps) => {
  const i18n = useTranslation();
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});
  const { approvedBy, pendingApprovalBy } = useApproversStatus(
    currentCompany.orgNumber,
    date,
    entitiesMap
  );
  const { draftEvents, pendingEvents, pendingRollbackEvents } =
    useCompanyEvents(currentCompany.orgNumber);
  const eventsToReview = isReview
    ? isRollback
      ? pendingRollbackEvents
      : pendingEvents
    : draftEvents;
  const createdReview =
    ledgerApprovalInitializedEvent &&
    entitiesMap[ledgerApprovalInitializedEvent.user.id];

  if (showAddEvent) {
    return (
      <SelectEvent
        currentCompany={currentCompany}
        onSuccess={() => {
          setFormData(defaultFormData);
          setSelectedEvent(undefined);
          setShowAddEvent(false);
        }}
        setFormData={setFormData}
        SelectedEvent={SelectedEvent}
        setSelectedEvent={setSelectedEvent}
      />
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-pb-4 max-md:tw-p-4">
      <div className="tw-flex tw-flex-col tw-gap-1">
        <h1 className="tw-text-2xl tw-font-medium">
          {isReview
            ? isRollback
              ? i18n.t("eventsWizard.rollback.title")
              : i18n.t("eventsWizard.review.title")
            : i18n.t("eventsWizard.add.title")}
        </h1>
        <p className="tw-text-sm">
          {isReview
            ? isRollback
              ? i18n.t("eventsWizard.rollback.description")
              : i18n.t("eventsWizard.review.description", {
                  name: createdReview?.name,
                  date:
                    ledgerApprovalInitializedEvent &&
                    getFormattedDate(
                      new Date(ledgerApprovalInitializedEvent.created)
                    ),
                })
            : i18n.t("eventsWizard.add.description")}
        </p>
      </div>
      {isReview && (
        <div className="tw-rounded md:tw-border md:tw-p-4">
          <div className="tw-flex tw-flex-col tw-gap-2">
            <ApproversList
              approvers={approvedBy}
              badge={<StatusBadge status="approved" />}
            />
            <ApproversList
              approvers={pendingApprovalBy}
              badge={<StatusBadge status="pending" />}
            />
          </div>
        </div>
      )}
      <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded md:tw-border">
        <EventsTable
          events={eventsToReview}
          currentCompany={currentCompany}
          entitiesMap={entitiesMap}
          expandedEvents={expandedEvents}
          setExpandedEvents={setExpandedEvents}
          isRollback={isRollback}
          emptyState={
            <EmptyState
              title={i18n.t("eventsWizard.table.empty.title")}
              description={i18n.t("eventsWizard.table.empty.description")}
            />
          }
        />
        {!isReview && (
          <div className="md:tw-p-4">
            <Button
              onClick={() => setShowAddEvent(true)}
              className="tw-w-full"
              prefix={<PlusIcon />}
            >
              {i18n.t("events.add")}
            </Button>
          </div>
        )}
      </div>
      {eventsToReview.length > 0 && !isRollback && (
        <ShareholdersComparison
          currentCompany={currentCompany}
          entitiesMap={entitiesMap}
        />
      )}
    </div>
  );
};

type AddEventsProps = {
  currentCompany: CompanyInformation | CompanyInvolvement;
  entitiesMap: EntitiesMap;
  onClose: () => void;
};

const defaultFormData = {
  formId: "",
  loading: false,
  selectedShareTypes: [],
};

const EventsWizard = ({
  currentCompany,
  entitiesMap,
  onClose,
}: AddEventsProps) => {
  const i18n = useTranslation();
  const [isExiting, setIsExiting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [success, setSuccess] = useState("");
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [formData, setFormData] = useState<EventFormData>(defaultFormData);
  const [SelectedEvent, setSelectedEvent] = useState<
    ((props: EventFormProps) => JSX.Element) | undefined
  >(undefined);
  const { formId, loading, selectedShareTypes } = formData;

  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const events = eventsQuery.data?.data || [];
  const eventsWithLabels = getEventsWithLabel(events);
  const { draftEvents, pendingEvents, pendingRollbackEvents } =
    useCompanyEvents(currentCompany.orgNumber);
  const ledgerApprovalInitializedEvent:
    | LedgerApprovalInitializedEvent
    | undefined = events.find((x) => x.type === "LedgerApprovalInitialized");
  const rollbackPending = eventsWithLabels.find(
    (e) => e.type === "LedgerRollbackPending"
  ) as LedgerRollbackPending & { label: string };

  // Use useState to prevent from updating when the query refetches
  const [isRollback] = useState(() => pendingRollbackEvents.length > 0);
  const [isReview] = useState(
    () => pendingEvents.length > 0 || pendingRollbackEvents.length > 0
  );
  const [event] = useState(isRollback ? rollbackPending : pendingEvents[0]);

  const { canApprove, hasApproved } = useShouldShowApproveButtons(
    currentCompany,
    event?.date
  );
  const useDeleteConfirmation = !isReview && draftEvents.length > 0;

  const handleClose = () => {
    setIsExiting(true);
    setShowDeleteConfirmation(false);
    setTimeout(() => {
      setIsExiting(false);
      onClose();
    }, 200);
  };

  const step = success ? (
    <Done
      currentCompany={currentCompany}
      isReview={isReview}
      isRollback={isRollback}
      event={event}
      isRejected={success === "reject"}
    />
  ) : (
    <AddEventsPage
      currentCompany={currentCompany}
      entitiesMap={entitiesMap}
      showAddEvent={showAddEvent}
      setShowAddEvent={setShowAddEvent}
      setFormData={setFormData}
      SelectedEvent={SelectedEvent}
      setSelectedEvent={setSelectedEvent}
      ledgerApprovalInitializedEvent={ledgerApprovalInitializedEvent}
      isReview={isReview}
      isRollback={isRollback}
      date={event?.date}
    />
  );

  const actions = useMemo(() => {
    if (success) {
      return [
        <Button
          key="next"
          variant="solid"
          color="primary"
          className="tw-w-full md:tw-w-fit"
          onClick={() => onClose()}
        >
          {i18n.t("label.done")}
        </Button>,
      ];
    }
    if (showAddEvent) {
      return [
        <Button
          key="back"
          className="tw-w-full md:tw-w-fit"
          onClick={() => {
            if (SelectedEvent) {
              setFormData({
                formId: "",
                loading: false,
                selectedShareTypes: [],
              });
              setSelectedEvent(undefined);
            } else {
              setShowAddEvent(false);
            }
          }}
        >
          {i18n.t("label.back")}
        </Button>,
        ...(formId
          ? [
              <FormActions
                key="actions"
                formId={formId}
                isLoading={loading}
                orgNumber={currentCompany.orgNumber}
                selectedShareTypes={selectedShareTypes}
              />,
            ]
          : []),
      ];
    }
    if (isReview && (canApprove || hasApproved) && event) {
      return [
        <RejectButton
          key="reject"
          currentCompany={currentCompany}
          currentVersion={event.date}
          isRollback={isRollback}
          onSuccess={() => setSuccess("reject")}
        />,
        <ApproveButton
          key="approve"
          currentCompany={currentCompany}
          event={event}
          isRollback={isRollback}
          canApprove={canApprove}
          disabled={!canApprove || hasApproved}
          onSuccess={() => {
            setSuccess("approve");
          }}
        />,
      ];
    }
    if (!isReview) {
      return [
        <PublishButton
          key="publish"
          currentCompany={currentCompany}
          entitiesMap={entitiesMap}
          version={draftEvents[0]?.date}
          onSuccess={() => setSuccess("publish")}
        />,
      ];
    }
    return [];
  }, [success, showAddEvent, isReview, canApprove, draftEvents]);

  return (
    <Dialog
      actions={actions}
      isLoading={false}
      isExiting={isExiting}
      showCancel={!success && !showAddEvent}
      onClose={() =>
        useDeleteConfirmation ? setShowDeleteConfirmation(true) : handleClose()
      }
    >
      <PageWrapper className="tw-flex tw-h-full tw-max-w-3xl tw-flex-col tw-justify-start tw-px-0 tw-pb-4 tw-pt-6">
        {step}
      </PageWrapper>
      {showDeleteConfirmation && (
        <DeleteConfirmation
          onClose={() => setShowDeleteConfirmation(false)}
          onSuccess={handleClose}
          currentCompany={currentCompany}
        />
      )}
    </Dialog>
  );
};

export { EventsWizard };
