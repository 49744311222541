import type {
  CollectAuthResponse,
  MockAuthRequest,
} from "@capchapdev/auth-api";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import type { TestAuthMock } from "../../../types/models/auth";
import { TestAuthMockSchema } from "../../../types/models/auth";
import * as monitoring from "../../../utils/monitoring";
import type { IRequestError } from "../..";
import useClient, { URL } from "../client";

const useInitMockBankIdMutation = (
  options?: UseMutationOptions<TestAuthMock, IRequestError, MockAuthRequest>
) => {
  const client = useClient();
  return useMutation(
    async ({
      refId,
      pubKey,
      countryCode,
    }: MockAuthRequest): Promise<TestAuthMock> => {
      const response = await client<CollectAuthResponse>(
        `${URL.API}/Test/Auth/Mock`,
        {
          body: { refId, pubKey, countryCode },
        },
        [400]
      );

      const result = TestAuthMockSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return response as TestAuthMock;
      }

      return result.data;
    },
    options
  );
};

type MockTruidAuthRequest = {
  passportNumber: string;
  TruidUserId: string;
  countryCode: string;
  pubKey: string;
};

const useInitMockTruidMutation = (
  options?: UseMutationOptions<
    TestAuthMock,
    IRequestError,
    MockTruidAuthRequest
  >
) => {
  const client = useClient();
  return useMutation(
    async (req: MockTruidAuthRequest): Promise<TestAuthMock> => {
      const response = await client<CollectAuthResponse>(
        `${URL.API}/Test/Auth/MockTruID`,
        {
          body: req,
        }
      );

      const result = TestAuthMockSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return response as TestAuthMock;
      }

      return result.data;
    },
    options
  );
};

const MockedSignResponseSchema = z.object({
  signature: z.string(),
});

type MockedSignResponse = z.infer<typeof MockedSignResponseSchema>;

const useGetMockedSignatureMutation = (
  options?: UseMutationOptions<string | null, IRequestError>
) => {
  const client = useClient();
  return useMutation(async () => {
    const response = await client<MockedSignResponse>(
      `${URL.API}/Test/Auth/GenerateMockSign`
    );

    const result = MockedSignResponseSchema.safeParse(response);

    if (!result.success) {
      monitoring.captureException(result.error, {
        contexts: { response, result },
      });

      return null;
    }

    return result.data.signature;
  }, options);
};

export default {
  useInitMockBankIdMutation,
  useInitMockTruidMutation,
  useGetMockedSignatureMutation,
};

export type { MockTruidAuthRequest };
