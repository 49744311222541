import {
  format,
  isAfter,
  isDate as dateFnsIsDate,
  isEqual,
  isValid,
  parse,
} from "date-fns";

import type { DateVersion, LedgerVersion } from "../types/models/shares";

/**
 * date-fns isDate exports an incorrect type, so this type guard is needed until
 * it is fixed upstream.
 * @see https://github.com/date-fns/date-fns/issues/3491#issue-1847821983
 */
const isDate = (date: unknown): date is Date => dateFnsIsDate(date);

const DATE_FORMAT = "y-MM-dd";

const dateToIsoString = (value?: Date): string =>
  value ? format(value, DATE_FORMAT) : "";

const isoStringToDate = (value?: string | null): Date | undefined => {
  const parsed = value ? parse(value, DATE_FORMAT, new Date()) : undefined;

  return isValid(parsed) ? parsed : undefined;
};

const getFormattedDate = (date?: Date | string): string | undefined => {
  const valueAsDate = isDate(date) ? date : isoStringToDate(date);

  if (valueAsDate === undefined) {
    return undefined;
  }

  return format(valueAsDate, DATE_FORMAT);
};

const getFormattedTime = (date: Date = new Date()): string =>
  format(date, "P kk:mm");

const getFormattedLedgerVersion = (
  dateVersion?: LedgerVersion
): DateVersion => {
  const fallbackDate = new Date();
  const fallbackLedgerVersion: DateVersion = {
    date: fallbackDate,
    version: "1",
    formatedValue: `${dateToIsoString(fallbackDate)}.1`,
  };
  if (!dateVersion) {
    return fallbackLedgerVersion;
  }
  try {
    const [date, version = "1"] = dateVersion.split(".");

    return {
      date: isoStringToDate(date) ?? new Date(),
      version,
      formatedValue: dateVersion,
    };
  } catch {
    return fallbackLedgerVersion;
  }
};

const isGreaterLedgerVersion = (
  target: LedgerVersion,
  limit?: LedgerVersion | null
): boolean => {
  if (!limit) {
    return true;
  }
  const targetLedgerVersion = getFormattedLedgerVersion(target);
  const limitLedgerVersion = getFormattedLedgerVersion(limit);

  if (isEqual(targetLedgerVersion.date, limitLedgerVersion.date)) {
    return (
      Number(targetLedgerVersion.version) > Number(limitLedgerVersion.version)
    );
  }

  return isAfter(targetLedgerVersion.date, limitLedgerVersion.date);
};

export {
  dateToIsoString,
  getFormattedDate,
  getFormattedLedgerVersion,
  getFormattedTime,
  isGreaterLedgerVersion,
  isoStringToDate,
};
