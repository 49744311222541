/* eslint-disable react/jsx-no-literals */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import AddBoardMemberDialog from "../../components/AddBoardMember/AddBoardMemberDialog";
import { Menu } from "../../components/design-system/Menu";
import { environment } from "../../config";
import { APP_ROUTE } from "../../routes/constants";
import { isTestCompany } from "../../utils/company";

type CompanyActionsProps = {
  orgNumber: string;
  id: string;
};

const CompanyActions = ({ orgNumber, id }: CompanyActionsProps) => {
  const i18n = useTranslation();
  const [isAddBoarMemberModalOpen, setAddBoarMemberModalOpen] = useState(false);

  return (
    <>
      <Menu>
        <Menu.Button className="tw-rounded-md" data-testid={id}>
          Actions
        </Menu.Button>
        <Menu.Items align="bottomRight">
          {environment !== "production" && (
            <>
              <Link
                to={`${APP_ROUTE.ADMINISTRATION}/${orgNumber}/update-status`}
              >
                <Menu.Item>{i18n.t("label.updateStatus")}</Menu.Item>
              </Link>
              {isTestCompany(orgNumber) && (
                <Menu.Item
                  closeOnClick
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setAddBoarMemberModalOpen(true);
                  }}
                >
                  {i18n.t("label.addBoardMember")}
                </Menu.Item>
              )}
            </>
          )}
          <Link to={`${APP_ROUTE.ADMINISTRATION}/${orgNumber}/update-policy`}>
            <Menu.Item>Update policy</Menu.Item>
          </Link>
        </Menu.Items>
      </Menu>
      {isAddBoarMemberModalOpen && (
        <AddBoardMemberDialog
          title={i18n.t("label.addBoardMember")}
          onClose={() => setAddBoarMemberModalOpen(false)}
          orgNumber={orgNumber}
          defaultValues={{
            refId: "",
            countryCode: "SE",
          }}
        />
      )}
    </>
  );
};

export default CompanyActions;
