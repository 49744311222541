import { ShareType } from "@capchapdev/rell-api";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../components/design-system/Button";
import { Dialog } from "../../../../../components/design-system/Dialog";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../../../components/design-system/FormGroup";
import { Input } from "../../../../../components/design-system/Input";
import { SelectClauses } from "../../../../../components/ShareTypes/SelectRestrictiveConditions";
import {
  NewShareType,
  TDraftShareType,
} from "../../../../../types/models/draft";
import logo from "../../../../companies/[companyId]/bv.svg";

const formId = "edit-sharetype-form";

type EditShareTypeDialogProps = {
  title: string;
  list: ShareType[];
  onClose: () => void;
  onSuccess: (value: ShareType) => void;
  companyShareClasses?: { name: string; votes?: number }[];
  defaultValues: NewShareType;
};

const EditShareTypeDialog = ({
  title,
  list,
  onClose,
  onSuccess,
  companyShareClasses,
  defaultValues,
}: EditShareTypeDialogProps) => {
  const i18n = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<TDraftShareType>({ mode: "onSubmit", defaultValues });

  const minVote = Math.min(
    ...list
      .filter((i) => i.name !== defaultValues.name)
      .map((type) => type.voteValue)
  );
  const maxVote = Math.max(
    ...list
      .filter((i) => i.name !== defaultValues.name)
      .map((type) => type.voteValue)
  );

  return (
    <Dialog
      isOpen
      title={title}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose}>{i18n.t("label.cancel")}</Button>
          <Button type="submit" variant="solid" color="primary" form={formId}>
            {i18n.t("label.save")}
          </Button>
        </>
      }
    >
      {companyShareClasses && companyShareClasses.length > 0 && (
        <div className="tw-my-4 tw-rounded tw-bg-[#f0f9ff] tw-p-4 tw-text-[#0c4a6e]">
          <div className="tw-flex tw-gap-4">
            <img src={logo} alt="bolagsverket-logo" />
            <div>
              <p className="tw-text-sm tw-font-medium">
                {i18n.t("events.issue.newClasses.autofill.title")}
              </p>
              <p className="tw-text-sm">
                {i18n.t("events.issue.newClasses.autofill.description")}
              </p>
            </div>
          </div>
          <div className="tw-mt-4 tw-flex tw-flex-col tw-gap-4">
            {companyShareClasses?.map((c) => (
              <div className="tw-flex tw-justify-between" key={c.name}>
                <div>
                  <p className="tw-text-sm tw-font-medium">{c.name}</p>
                  {c.votes && (
                    <p className="tw-text-sm">
                      {i18n.t("label.numberOfVotesPerShare.count", {
                        count: c.votes,
                      })}
                    </p>
                  )}
                </div>
                {list.find((l) => l.name === c.name) ? (
                  <p className="tw-flex tw-items-center tw-text-sm tw-font-medium">
                    {i18n.t("events.issue.newClasses.autofill.disabled")}
                  </p>
                ) : (
                  <Button
                    variant="solid"
                    color="primary"
                    onClick={() => {
                      setValue("name", c.name);
                      if (c.votes) {
                        setValue("voteValue", c.votes);
                      }
                    }}
                  >
                    {i18n.t("events.issue.newClasses.autofill.add")}
                  </Button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      <form
        className="tw-space-y-4"
        onSubmit={(event) => {
          event.stopPropagation();

          return handleSubmit((data) =>
            onSuccess({ ...data, name: data.name.trim() })
          )(event);
        }}
        id={formId}
      >
        <FormGroup>
          <FormLabel htmlFor="name">{i18n.t("label.name")}</FormLabel>
          <Input
            id="name"
            {...register("name", {
              maxLength: {
                value: 30,
                message: i18n.t("error.validation.maxCharacters", { max: 30 }),
              },
              validate: (value) => {
                if (!value.trim()) {
                  return i18n.t("error.validation.required");
                }
                if (
                  value !== defaultValues.name &&
                  list.some(
                    (sibling) =>
                      sibling.name.trim().toLowerCase() ===
                      value.trim().toLowerCase()
                  )
                ) {
                  return i18n.t("error.validation.unique");
                }

                return true;
              },
            })}
          />
          <FormError>{errors.name?.message}</FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="voteValue">
            {i18n.t("label.numberOfVotesPerShare")}
          </FormLabel>
          <Input
            id="voteValue"
            {...register("voteValue", {
              required: i18n.t("error.validation.required"),
              valueAsNumber: true,
              validate: (val) => {
                if (Number.isNaN(val) || val <= 0) {
                  return i18n.t("error.validation.range.greaterThanOrEqual", {
                    min: 0,
                  });
                }

                const range = { min: maxVote / 10, max: minVote * 10 };
                if (val < range.min || val > range.max) {
                  return i18n.t("error.validation.range.votes", range);
                }

                return true;
              },
            })}
            step={0.01}
            type="number"
          />
          <FormError>{errors.voteValue?.message}</FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="condition" isOptional>
            {i18n.t("shares.restrictiveConditions")}
          </FormLabel>
          <Controller
            name="condition"
            control={control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <SelectClauses
                  value={value}
                  onChange={onChange}
                  menuPosition="fixed"
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
      </form>
    </Dialog>
  );
};

export { EditShareTypeDialog };
