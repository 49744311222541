import { ArrowSquareOut, ThumbsUp } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useChildEventsQuery } from "../../api/blockchain/events";
import { Button } from "../../components/design-system/Button";
import { Description } from "../../components/design-system/Description";
import { Disclosure } from "../../components/design-system/Disclosure";
import { Loading } from "../../components/design-system/Loading";
import type {
  CompanyInformation,
  CompanyShareCapitalHistory,
} from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import { TParentEvent } from "../../types/models/events";
import { getFormattedDate } from "../../utils/date";
import { formatCurrency, formatNumber } from "../../utils/format";
import banner from "./banner.svg";
import {
  calculateRatio,
  useCompanyDataComparison,
} from "./EventsValidation.utils";

type EventType =
  | "CompanyFoundation"
  | "ShareIssue"
  | "ShareSplit"
  | "ReverseShareSplit"
  | "IncreaseCapitalBonusIssue"
  | "DecreaseCapital"
  | "DecreaseCapitalCancelShares";

type MissingEventProps = {
  type: EventType;
  date: string;
  details: { [key: string]: string };
  expanded: boolean;
  setExpanded: (value: boolean) => void;
};

const MissingEventCard = ({
  type,
  date,
  details,
  expanded,
  setExpanded,
}: MissingEventProps) => {
  const i18n = useTranslation();
  const eventTitles: {
    [k in EventType]: string;
  } = {
    CompanyFoundation: i18n.t("events.companyFoundation.title"),
    ShareIssue: i18n.t("events.issue.title"),
    IncreaseCapitalBonusIssue: i18n.t("events.bonusIssue.title"),
    DecreaseCapitalCancelShares: i18n.t(
      "events.decreaseCapitalCancelShares.title"
    ),
    DecreaseCapital: i18n.t("events.reduceCapital.title"),
    ShareSplit: i18n.t("events.split.title"),
    ReverseShareSplit: i18n.t("events.reverseSplit.title"),
  };
  const title = eventTitles[type];
  return (
    <div className="tw-rounded tw-bg-white tw-p-4 tw-text-primary">
      <Disclosure className="tw-flex tw-flex-col tw-gap-2">
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-text-sm">
            <p>{title}</p>
            <p className="tw-text-secondary">{getFormattedDate(date)}</p>
          </div>
          <div>
            <Disclosure.Button
              open={expanded}
              onClick={() => setExpanded(!expanded)}
            />
          </div>
        </div>
        <Disclosure.Panel>
          <div className="tw-flex tw-flex-wrap tw-gap-4">
            {Object.entries(details).map(([k, v]) => (
              <Description
                title={k}
                description={v}
                key={k}
                theme="grayBlack"
              />
            ))}
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
};

type MissingAtBvCardProps = {
  event: TParentEvent;
  orgNumber: string;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
};

const MissingAtBvCard = ({
  event,
  orgNumber,
  expanded,
  setExpanded,
}: MissingAtBvCardProps) => {
  const i18n = useTranslation();
  const childEventsQuery = useChildEventsQuery(orgNumber, event.id);
  const childEvents = childEventsQuery.data || [];
  const shareClasses = Array.from(
    new Set(childEvents.map((e) => e.shares.type))
  );
  const data = {
    ...("shares" in event &&
      "total" in event.shares && {
        [i18n.t("validate.data.shares")]: formatNumber(event.shares.total),
      }),
    ...("shares" in event &&
      "capital" in event.shares && {
        [i18n.t("validate.data.capital")]: formatCurrency(
          parseFloat(event.shares.capital),
          "SEK"
        ),
      }),
    ...("shares" in event &&
      "investment" in event.shares &&
      event.shares.investment &&
      parseFloat(event.shares.investment) !== 0 && {
        [i18n.t("validate.data.investment")]: formatCurrency(
          parseFloat(event.shares.investment),
          "SEK"
        ),
      }),
    ...((event.type === "ShareSplit" || event.type === "ReverseShareSplit") && {
      [i18n.t("validate.data.ratio")]: `${event.ratio.x}:${event.ratio.y}`,
    }),
    ...(childEvents.length > 0 && {
      [i18n.t("validate.data.shareClasses")]: shareClasses.join(", "),
    }),
  };

  return (
    <MissingEventCard
      type={event.type as EventType}
      date={event.date.split(".")[0]!}
      details={data}
      expanded={expanded}
      setExpanded={setExpanded}
    />
  );
};

type MissingInLedgerCardProps = {
  event: CompanyShareCapitalHistory;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
};

const MissingInLedgerCard = ({
  event,
  expanded,
  setExpanded,
}: MissingInLedgerCardProps) => {
  const i18n = useTranslation();
  const data = {
    [i18n.t("validate.data.caseId")]: event.id,
    ...(event.changes.shares && {
      [i18n.t("validate.data.shares")]: formatNumber(event.changes.shares),
    }),
    ...(event.changes.capital && {
      [i18n.t("validate.data.capital")]: formatCurrency(
        event.changes.capital,
        "SEK"
      ),
    }),
    ...(event.payment && {
      [i18n.t("validate.data.investment")]: formatCurrency(
        event.payment,
        "SEK"
      ),
    }),
    ...(event.type === "ShareSplit" && {
      [i18n.t("validate.data.ratio")]: calculateRatio(
        event.shares - event.changes.shares,
        event.changes.shares
      ),
    }),
    ...(event.type === "ShareIssue" &&
      event.shareClasses.length > 0 && {
        [i18n.t("validate.data.shareClasses")]: event.shareClasses
          .map((s) => s.name)
          .join(", "),
      }),
  };

  return (
    <MissingEventCard
      type={event.type as EventType}
      date={event.decisionDate || event.date}
      details={data}
      expanded={expanded}
      setExpanded={setExpanded}
    />
  );
};

type EventsValidationProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

const EventsValidation: React.FunctionComponent<EventsValidationProps> = ({
  currentCompany,
}) => {
  const i18n = useTranslation();
  const [expanded, setExpanded] = useState<string[]>([]);
  const companyDataMismatch = useCompanyDataComparison(
    currentCompany.orgNumber
  );
  const ledgerOnly =
    companyDataMismatch?.filter((x) => x.ledger && !x.companyData) || [];
  const companyDataOnly =
    companyDataMismatch?.filter((x) => !x.ledger && x.companyData) || [];

  if (!companyDataMismatch) {
    return <Loading />;
  }

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-6 max-md:tw-pt-4">
        <div className="tw-rounded tw-bg-gradient-to-r tw-from-[#f3e8ff] tw-to-[#f7f5fa] tw-p-6">
          <img src={banner} className="tw-mx-auto" alt="banner" />
        </div>
        <div>
          <h1 className="tw-text-2xl tw-font-medium tw-text-primary">
            {i18n.t("validate.title")}
          </h1>
          <p className="tw-text-sm tw-text-primary">
            {i18n.t("validate.description")}
          </p>
        </div>
        {ledgerOnly.length === 0 && companyDataOnly.length === 0 && (
          <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded tw-border tw-bg-green-50 tw-p-6 tw-text-green-900">
            <div>
              <h2 className="tw-text-xl tw-font-medium">
                {i18n.t("validate.valid.title")}
              </h2>
              <p className="tw-text-sm">
                {i18n.t("validate.valid.description")}
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-2">
              <p className="tw-flex tw-items-center tw-gap-2 tw-text-sm">
                <ThumbsUp />
                {i18n.t("validate.valid.description.ledger")}
              </p>
            </div>
          </div>
        )}
        {companyDataOnly.length > 0 && (
          <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded tw-border tw-bg-amber-100 tw-p-6 tw-text-amber-900">
            <div>
              <h2 className="tw-text-xl tw-font-medium">
                {i18n.t("validate.ledger.title")}
              </h2>
              <p className="tw-text-sm">
                {i18n.t("validate.ledger.description")}
              </p>
            </div>
            {companyDataOnly.map(
              ({ companyData }) =>
                companyData && (
                  <MissingInLedgerCard
                    key={companyData.id}
                    event={companyData}
                    expanded={expanded.includes(companyData.id)}
                    setExpanded={(value) =>
                      !value
                        ? setExpanded(
                            expanded.filter((x) => x !== companyData.id)
                          )
                        : setExpanded([...expanded, companyData.id])
                    }
                  />
                )
            )}
            <Link
              to={`/companies/${currentCompany.orgNumber}/events`}
              className="tw-w-fit"
            >
              <Button color="primary" variant="solid">
                {i18n.t("validate.ledger.button")}
              </Button>
            </Link>
          </div>
        )}
        {ledgerOnly.length > 0 && (
          <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded tw-border tw-bg-amber-100 tw-p-6 tw-text-amber-900">
            <div>
              <h2 className="tw-text-xl tw-font-medium">
                {i18n.t("validate.bv.title")}
              </h2>
              <p className="tw-text-sm">{i18n.t("validate.bv.description")}</p>
            </div>
            {ledgerOnly.map(
              ({ ledger }) =>
                ledger && (
                  <MissingAtBvCard
                    key={ledger.id}
                    event={ledger}
                    orgNumber={currentCompany.orgNumber}
                    expanded={expanded.includes(ledger.id)}
                    setExpanded={(value) =>
                      !value
                        ? setExpanded(expanded.filter((x) => x !== ledger.id))
                        : setExpanded([...expanded, ledger.id])
                    }
                  />
                )
            )}
            <Link
              to="https://bolagsverket.se/"
              target="_blank"
              className="tw-w-fit"
            >
              <Button
                color="primary"
                variant="solid"
                className="tw-flex tw-gap-2"
              >
                Bolagsverket.se <ArrowSquareOut />
              </Button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export { EventsValidation };
