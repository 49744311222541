import { useTranslation } from "react-i18next";

import { Description } from "../../components/design-system/Description";
import { getFormattedDate } from "../../utils/date";

type DetailProps = {
  companyId: string;
  created: string;
  version: string;
};

const Details = ({ companyId, created, version }: DetailProps) => {
  const i18n = useTranslation();

  return (
    <div className="tw-flex tw-h-fit tw-flex-col tw-gap-4 tw-whitespace-nowrap tw-rounded-lg tw-border tw-border-neutral-200 tw-bg-neutral-50 tw-p-4">
      <Description
        title={i18n.t("label.organizationId")}
        description={companyId}
      />
      <Description
        title={i18n.t("externalView.created")}
        description={getFormattedDate(created.split("T")[0])}
      />
      <Description
        title={i18n.t("externalView.version")}
        description={getFormattedDate(version.split(".")[0])}
      />
    </div>
  );
};

export { Details };
