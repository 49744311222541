import logo from "../../pages/companies/[companyId]/bv.svg";

type BvBannerProps = {
  title: string;
  description: string;
};

const BvBanner = ({ title, description }: BvBannerProps) => (
  <div className="tw-flex tw-items-start tw-gap-4 tw-rounded tw-bg-[#f0f9ff] tw-p-4 tw-text-[#0c4a6e]">
    <img src={logo} alt="bolagsverket-logo" />
    <div>
      <p className="tw-text-sm tw-font-medium">{title}</p>
      <p className="tw-text-sm">{description}</p>
    </div>
  </div>
);

export { BvBanner };
