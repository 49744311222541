import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import {
  AddSharedViewResponse,
  AddSharedViewResponseSchema,
  CreateSharedView,
  SharedView,
  SharedViewListSchema,
  Shareholders,
  ShareholdersSchema,
  Verify,
  VerifyCode,
  VerifyCodeResponse,
  VerifyCodeResponseSchema,
  VerifyResponseSchema,
} from "../../types/models/views";
import * as monitoring from "../../utils/monitoring";
import { IRequestError } from "..";
import { getAuthHeader } from "../auth";
import useClient, { NoContentSchema, URL } from "./client";

const getSharedViews = async (orgNumber: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<SharedView[]>(
    `${URL.INFO_SHARING}/sharedviews/${orgNumber}`
  );

  const result = SharedViewListSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { data: { response }, result },
    });

    return response;
  }

  return result.data;
};

const useSharedViewsQuery = (orgNumber?: string) =>
  useQuery(["sharedViews", orgNumber], () => getSharedViews(orgNumber!), {
    enabled: orgNumber !== undefined,
  });

const addSharedView = async (
  orgNumber: string,
  body: CreateSharedView
): Promise<AddSharedViewResponse> => {
  const client = useClient({ hasAuth: true });

  const response = await client<AddSharedViewResponse>(
    `${URL.INFO_SHARING}/sharedviews/${orgNumber}`,
    { method: "POST", body }
  );

  const result = AddSharedViewResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const useAddSharedViewMutation = (
  orgNumber: string,
  options?: UseMutationOptions<
    AddSharedViewResponse,
    IRequestError,
    CreateSharedView
  >
) => {
  const queryClient = useQueryClient();
  return useMutation<AddSharedViewResponse, IRequestError, CreateSharedView>(
    (data) => addSharedView(orgNumber, data),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: ["sharedViews", orgNumber],
        });
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};

const editSharedView = async (
  orgNumber: string,
  viewId: string,
  body: CreateSharedView
): Promise<AddSharedViewResponse> => {
  const client = useClient({ hasAuth: true });

  const response = await client<AddSharedViewResponse>(
    `${URL.INFO_SHARING}/sharedviews/${orgNumber}/${viewId}`,
    { method: "PUT", body }
  );

  const result = AddSharedViewResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const useEditSharedViewMutation = (
  orgNumber: string,
  viewId?: string,
  options?: UseMutationOptions<
    AddSharedViewResponse,
    IRequestError,
    CreateSharedView
  >
) => {
  const queryClient = useQueryClient();
  return useMutation<AddSharedViewResponse, IRequestError, CreateSharedView>(
    (data) => {
      if (viewId) {
        return editSharedView(orgNumber, viewId, data);
      }
      throw new Error("No view id");
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: ["sharedViews", orgNumber],
        });
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};

const deleteSharedView = async (orgNumber: string, viewId: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.INFO_SHARING}/sharedviews/${orgNumber}/${viewId}`,
    { method: "DELETE" }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const useDeleteSharedViewMutation = (
  orgNumber?: string,
  options?: UseMutationOptions<unknown, IRequestError, string>
) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, IRequestError, string>(
    (viewId) => {
      if (orgNumber) {
        return deleteSharedView(orgNumber, viewId);
      }
      throw new Error("No org number");
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: ["sharedViews", orgNumber],
        });
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};

const verifyView = async (body: Verify) => {
  const client = useClient({ hasAuth: false });

  const response = await client<undefined>(
    `${URL.INFO_SHARING}/external/views/verify`,
    { method: "POST", body },
    [404]
  );

  const result = VerifyResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const useVerifyViewMutation = (
  options?: UseMutationOptions<unknown, IRequestError, Verify>
) => {
  return useMutation<unknown, IRequestError, Verify>(
    (data) => verifyView(data),
    options
  );
};

const verifyCode = async (body: VerifyCode): Promise<VerifyCodeResponse> => {
  const client = useClient({ hasAuth: false });

  const response = await client<undefined>(
    `${URL.INFO_SHARING}/external/views/verify-code`,
    { method: "POST", body },
    [429, 400]
  );

  const result = VerifyCodeResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response as unknown as VerifyCodeResponse;
  }

  return result.data;
};

const useVerifyCodeMutation = (
  options?: UseMutationOptions<VerifyCodeResponse, IRequestError, VerifyCode>
) => {
  return useMutation<VerifyCodeResponse, IRequestError, VerifyCode>(
    (data) => verifyCode(data),
    options
  );
};

const refreshToken = async (body: {
  refreshToken: string;
}): Promise<VerifyCodeResponse> => {
  const client = useClient({ hasAuth: false });

  const response = await client<undefined>(
    `${URL.INFO_SHARING}/external/views/refresh-token`,
    { method: "POST", body },
    [429, 404]
  );

  const result = VerifyCodeResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response as unknown as VerifyCodeResponse;
  }

  return result.data;
};

const useRefreshTokenMutation = (
  options?: UseMutationOptions<
    VerifyCodeResponse,
    IRequestError,
    { refreshToken: string }
  >
) => {
  return useMutation<
    VerifyCodeResponse,
    IRequestError,
    { refreshToken: string }
  >((data) => refreshToken(data), options);
};

const getSharedView = async (
  orgNumber: string,
  viewId: string,
  token: string
) => {
  const client = useClient({ hasAuth: !token });

  let response;
  if (token) {
    // External
    response = await client<Shareholders>(
      `${URL.INFO_SHARING}/external/${orgNumber}/views/${viewId}/shareholders`,
      { headers: { Authorization: `Bearer ${token}` } },
      [404]
    );
  } else {
    // Internal
    response = await client<Shareholders>(
      `${URL.INFO_SHARING}/sharedviews/${orgNumber}/views/${viewId}/shareholders`,
      {},
      [404]
    );
  }

  const result = ShareholdersSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { data: { response }, result },
    });

    return response;
  }

  return result.data || null;
};

const useSharedViewQuery = (
  orgNumber?: string,
  viewId?: string,
  token?: string,
  options?: UseQueryOptions<unknown, IRequestError, Shareholders, string[]>
) =>
  useQuery(
    ["sharedView", orgNumber || "", viewId || ""],
    () => getSharedView(orgNumber!, viewId!, token!),
    { enabled: !!orgNumber && !!viewId && token !== undefined, ...options }
  );

const sharedViewDocumentDownload = async (
  orgNumber: string,
  viewId: string,
  language: string,
  token?: string
) => {
  if (token) {
    // External
    return await fetch(
      `${URL.INFO_SHARING}/external/${orgNumber}/views/${viewId}/shareholders/download?language=${language}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }
  // Internal
  const authHeader = getAuthHeader();
  return await fetch(
    `${URL.INFO_SHARING}/sharedviews/${orgNumber}/views/${viewId}/shareholders/download?language=${language}`,
    { headers: { Authorization: JSON.stringify(authHeader) } }
  );
};

export {
  sharedViewDocumentDownload,
  useAddSharedViewMutation,
  useDeleteSharedViewMutation,
  useEditSharedViewMutation,
  useRefreshTokenMutation,
  useSharedViewQuery,
  useSharedViewsQuery,
  useVerifyCodeMutation,
  useVerifyViewMutation,
};
