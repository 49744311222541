import { z } from "zod";

const AttachmentsSchema = z.array(
  z.object({
    name: z.string(),
    size: z.number(),
    created: z.number(),
    userId: z.string(),
  })
);

type Attachments = z.infer<typeof AttachmentsSchema>;

const EventAttachmentsSchema = z.record(AttachmentsSchema);

type EventAttachments = z.infer<typeof EventAttachmentsSchema>;

const SignedUrlSchema = z.object({
  url: z.string(),
});

type SignedUrl = z.infer<typeof SignedUrlSchema>;

const SignedUrlWithUserIdSchema = SignedUrlSchema.extend({
  id: z.string(),
});

type SignedUrlWithUserId = z.infer<typeof SignedUrlWithUserIdSchema>;

export {
  AttachmentsSchema,
  EventAttachmentsSchema,
  SignedUrlSchema,
  SignedUrlWithUserIdSchema,
};
export type { Attachments, EventAttachments, SignedUrl, SignedUrlWithUserId };
