import type { NewEntity } from "../../../types/models/entities";
import {
  validateSwedishCompanyNumber,
  validateSwedishPersonalNumber,
} from "../../../utils/validation";

const COUNTRIES_WITHOUT_REGION = ["SE"] as const;

const shouldShowRegion = (countryCode: string): boolean =>
  !COUNTRIES_WITHOUT_REGION.some((cwr) => cwr === countryCode);

const canAutoFill = (
  formValues: Pick<NewEntity, "countryCode" | "refId" | "type">
): boolean =>
  formValues.countryCode === "SE" &&
  ((formValues.type === "Private" &&
    !validateSwedishPersonalNumber(formValues.refId ?? "")) ||
    (formValues.type === "Company" &&
      !validateSwedishCompanyNumber(formValues.refId ?? "")));

export { canAutoFill, shouldShowRegion };
